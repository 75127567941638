import { Action, State, StateContext } from '@ngxs/store';
import { PatchStock, ResetStock } from './stock.actions';
import { StockStateModel } from './stock-state.model';
import { Injectable } from '@angular/core';

@State<Partial<StockStateModel>>({
  name: 'stock',
  defaults: {},
})
@Injectable()
export class StockState {
  @Action(PatchStock)
  public patch(ctx: StateContext<Partial<StockStateModel>>, { stock }: PatchStock): void {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      ...stock,
    });
  }

  @Action(ResetStock)
  public reset(ctx: StateContext<Partial<StockStateModel>>): void {
    ctx.setState({});
  }
}
